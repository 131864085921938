.App {
  min-height: 100vh;
  background: rgb(37, 37, 37);
  background: linear-gradient(
    0deg,
    rgba(37, 37, 37, 1) 18%,
    rgba(88, 88, 88, 1) 43%,
    rgba(121, 121, 121, 1) 68%,
    rgba(172, 172, 172, 1) 94%
  );
  font-family: Share Tech Mono;
}
.playing-station {
  font-size: 30px;
}
